export class DateUtils {

      static toHHMMSS_HELPER = (seconds: number ) => {

            var hours   = Math.floor(seconds / 3600)
            var minutes = Math.floor((seconds % 3600) / 60);
            var sec = Math.floor(seconds % 60)
            console.log({ hours: hours, minutes: minutes, seconds: sec } );
            return { hours: hours, minutes: minutes, seconds: sec }
        }

        /** returns h:m:s in format 01:03:33  */
        static toHHMMSS_short = (seconds: number ) => {
            let result = DateUtils.toHHMMSS_HELPER( seconds );
            return [result.hours, result.minutes, result.seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v,i) => v !== "00" || i > 0)
            .join(":")

        }
         /** returns h:m:s in format 01h:03m:33s .  h and m are not displayed if 0  */
        static toHHMMSS_string = (seconds: number ) => {
            let result = DateUtils.toHHMMSS_HELPER( seconds );
            var hours = result.hours === 0 ? '' : `${result.hours.toString().padStart(2, '0')}h`;
            var minutes = result.minutes === 0 ? '' : `${result.minutes.toString().padStart(2, '0')}m`;
            var sec = `${result.seconds.toString().padStart(2, '0')}s`;
            console.log( `${hours} ${minutes} ${sec}` );
            return `${hours} ${minutes} ${sec}`
        
        }

        static format24To12(timeString: string) {
            const [hourString, minute] = timeString.split(":");
            const hour = +hourString % 24;
            return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
        }

        static toYYYYMMDDString( date: Date, addZeroHours: boolean = false ) {

                  var d = new Date(date);
                  var    month = '' + (d.getMonth() + 1);
                  var    day = '' + d.getDate();
                  var    year = d.getFullYear();
              
                  if (month.length < 2) 
                      month = '0' + month;
                  if (day.length < 2) 
                      day = '0' + day;
              
                  let dateString = [year, month, day].join('-');
                  if ( addZeroHours ) {
                        dateString += "T00:00:00"
                  }

                  return dateString;
        }

      static msToTime(ms: number, showUnits: boolean = false) {

            //            var milliseconds = Math.floor((ms % 1000) / 100);
            const seconds = Math.floor((ms / 1000) % 60).toString().padStart(2, '0');
            const minutes = Math.floor((ms / (1000 * 60)) % 60).toString().padStart(2, '0');
            const hours = Math.floor((ms / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');

            if (showUnits ) {
                 return `${hours}h:${minutes}m:${seconds}s`
            } else {
                  return `${hours}:${minutes}:${seconds}`
            }
      }
}