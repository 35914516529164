import useFetch from "../../hooks/use-fetch";
import { StationEntry, UserActivity } from '../../models/daily-activity.models'
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { User } from "../../models/user";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import Config from "../../config";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DurationUtils } from "../../utils/duration-utils";
import { StationsEnum } from "../../models/stations-enum";
import { DateTime } from "luxon";
import { ProgressSpinner } from "primereact/progressspinner";
import DateRangePicker from "../../date-pickers/date-range-picker";

/*
interface IDailyActivityReportProps {
      userId: string;
      date: string;
}
*/
interface DailyActivityReportResponse {
      date: string;
}

interface IReturnEntry {
      frameType: string,
      returnsOther: number,
      openBoxReturns: number
}
interface IShippedToday {
      premiere: number,
      alloy: number,
      samples: number
}

interface IStationEntrysByFrameType {
      data: {
            premiere: IStationEntry[];
            target: IStationEntry[];
            decoslim: IStationEntry[];
            alloy: IStationEntry[]
            grandTotals: {
                  totalWorkers: number,
                  framesCompleted: {
                        premiere: number,
                        alloy: number
                  }
            },
            returns: IReturnEntry[];
            shippedToday: IShippedToday;
            lastBoxingPrepInventory: {
                  date: string,
                  discrepancy: string,
            },
            lastFIPInventory: {
                  premiere:{
                        lastInventoryDate: boolean,
                        totalFIPs: number,
                        voided: number,
                        unvoided:  number,
                  }, 
                  decoslim: {
                        lastInventoryDate: boolean,
                        totalFIPs: number,
                        voided: number,
                        unvoided:  number,
                  }                  
                  alloy: {
                        lastInventoryDate: boolean,
                        totalFIPs: number,
                        voided: number,
                        unvoided:  number,
                  }
            },
            ticketStats: {
                  createdToday: number,
                  inProcess: number,
                  toShip: number,
                
            }
         

      }
      date: string;
}
interface IStationEntry {
      stationId: number;
      stationName: string;
      teamMemberCount: number;
      hours: IStationLabourTotals;
      frameType: "A" | "P";
      frames: IFrameEntry;
      tasks: ITaskEntry[];
      // for boxing prep only
      boxesMadeEvents?: string;
      boxesMadeSerials?: string;
      boxInventory?: string;
      boxLabelsVoidedLastInventory?: string;
}

interface IStationLabourTotals {
      stationId: number;
      stationName: string;
      timeAtStation: any;
      timeOnFrames: any;
}

interface ITaskEntry {
      stationTaskId: string;
      stationTaskGroupId: string;
      taskName: string;
      taskDescription: string;
      total: string;
}

interface IFrameEntry {
      completed: number,
      rejected: number,
      discarded: number,
      recut: number, // used in cutting only                         
}

interface IDailyActivityResponse {
      usersActivity: UserActivity[];
      activities: StationEntry[];
      users: User[];
}

interface IShowSideBarMenuProps {
      isLeftSidebarCollapsed: boolean;
      changeIsLeftSidebarCollapsed: (isLeftSidebarCollapsed: boolean) => void;
}

//(props: IDailyActivityReportProps) => {
const EndOfDaySlackReport = (props: IShowSideBarMenuProps) => {

      // make this work for a selected date and user if 
     // const [date, setDate] = useState<DateTime | null>(null);

      const [postingToSlack, setPostingToSlack] = useState<boolean>(false);
      const [startDate, setStartDate] = useState<DateTime | null>( DateTime.now().startOf('month') );
      const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

//      const [range, setRange] = useState< "day" | "weekly" | "month" | "quarter" >("day")

      const url = startDate && endDate && `${Config.baseUrl}/api/daily-summary/station-and-task-totals-2?startdate=${startDate.toFormat('yyyy-MM-dd')}T00:00:01&enddate=${endDate.toFormat('yyyy-MM-dd')}T23:59:59`;

      const [query, setQuery] = useState('');
      const { data, loading, error } = useFetch<IStationEntrysByFrameType>(url ?? "", [], {});

      const showHours = false;
/*
      function onChangeDate(days: number) {
            if (date) {
                  let newDate = date; 
                  if ( days > 0 ) {
                        newDate = date.plus({days: 1})
                  } else {
                        newDate = date.minus({days: 1})
                  }
                  setDate(newDate);
           //     NOT auto-setting url in this report since it hides the menu when a date is passing in the url params
           //     for purposes of generating reports
           //     setDateInUrlParams( newDate );

            }
      }
            */
      const setSideBarCollapsed = ( value: boolean ): void => {
            props.changeIsLeftSidebarCollapsed(value);
      };

      useEffect(() => {

            function closeIfDateParamInUrl() {

                  // if a ULRL params are included, assume the report is for posting to slack so hide the menu and date selector to keep the posting minimal
                  if (window.location.search) {
                        const urlParams = new URLSearchParams(window.location.search);
                        let date = urlParams.get('startDate')

                        if (date) {
                              const newDate =  DateTime.fromISO( date );
                              setStartDate( newDate );
                              let newEndDate = newDate.endOf( 'day' );
                              setEndDate( newEndDate );
                        }
                        
                        // for some reason it won't collapse on open unless delayed - hack around for now
                        setTimeout(() => {
                               props.changeIsLeftSidebarCollapsed(true);
                         }, 100);                         
                  }
            }

            closeIfDateParamInUrl();

      }, []);

   
      useEffect(() => {
            // sends an event to puppeteer when the page is fully loaded and data is displayed
            if ( loading === false && data && data.data && document.readyState === 'complete' ) {
                  console.log( 'data - app ready')
                  window.dispatchEvent(new Event("react-app-ready"));
            }

      }, [loading, data]);
/*
      function setDateInUrlParams( newDate: DateTime ){
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.set('startDate', newDate.toFormat('yyyy-MM-dd'));
            window.history.replaceState("", "", window.location.pathname + "?startDate=" + newDate.toFormat('yyyy-MM-dd'));
      }
*/
      function hasData(x: any) {
            return x && x.length > 0 ? true : false;
      }

      function totalCompleted() {
            let total = 0;
            // number boxes is total
            if (data && data.data) {
                  let boxedPremiere = data.data.premiere.find(e => e.stationId === StationsEnum.boxing)?.frames.completed ?? 0;
                  let boxedAlloy = data.data.alloy.find(e => e.stationId === StationsEnum.boxingAlloy)?.frames.completed ?? 0;
                  total = boxedPremiere + boxedAlloy
            }
            return total;
      }

      function totalTeamMembers() {
            let total = 0;
            if (data && data.data) {
                  const totaltypes = [data.data.premiere, data.data.alloy];
                  for (const frammeType of totaltypes) {
                        if (frammeType) {
                              for (const station of frammeType) {
                                    if (station && station.teamMemberCount) {
                                          total += station.teamMemberCount
                                    }
                              }
                        }
                  }
            }
            return total;
      }
/*
      function setNewDate( dateString: string ) {
            const newDate = DateTime.fromISO( dateString );
            setDate( newDate )
           //     NOT auto-setting url in this report since it hides the menu when a date is passing in the url params
           //     for purposes of generating reports
           //     setDateInUrlParams( newDate );
      }
*/
      return (
            <div className='report'>
                  {props.isLeftSidebarCollapsed === false &&
                        <div className="calendar-selector">
                              <div className='flex flex-row pl-5'>

                              <DateRangePicker startDateCallback={(dateString)=> {
                                                                        setStartDate( DateTime.fromISO( dateString ));
                                                                  }} 
                                                endDateCallback={(dateString) => {
                                                      setEndDate( DateTime.fromISO( dateString ));
                                                }} 

                                                showSingleDateSelector={true}
                                                />
                              </div>
                        </div>
                  }


                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> End of Day </div> 
                              <div className="report-date-title">{startDate?.toFormat( "ccc MMM-dd-yyyy")} {startDate && endDate && endDate?.toFormat( "MMM-dd-yyyy") !== startDate?.toFormat( "MMM-dd-yyyy") && <>to {endDate?.toFormat( "ccc MMM-dd-yyyy")}</>}</div>
                        </div>

                        {error && <p>An error occurred loading the report.</p>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}

                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>Premiere</h2>

                        <div>
                              <div className='eod-header-row'>
                                    <div className="row" >Station</div>
                                    {showHours && <div className="row" >Hours</div>}
                                    <div className="row" >Team Members</div>
                                    <div className="row" >Total</div>
                                    <div className="row" >Rejected</div>
                                    <div className="row" >Voided</div>
                                    <div className="row" >Recut</div>

                              </div>
                              {!hasData(data?.data?.premiere) &&
                                    <div className="text-left">There are no entries for the selected day</div>
                              }
                              {data && data.data && data.data.premiere && data.data.premiere.map((stationRow: IStationEntry) => {
                                    return (

                                          <>
                                                {stationRow.stationId !== 5 ?
                                                      <div className="eod-table-row" >
                                                            <div className="row" >{stationRow.stationName}</div>
                                                            {showHours && <div className="row">{stationRow.hours && (stationRow.hours.timeAtStation ? DurationUtils.formatDuration(stationRow.hours.timeAtStation) : 0)}</div>}
                                                            <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                            <div className="row" >{stationRow.frames.completed > 0 ? stationRow.frames.completed : ""}</div>
                                                            <div className="row" >{stationRow.frames.rejected > 0 ? stationRow.frames.rejected : ""}</div>
                                                            <div className="row" >{stationRow.frames.discarded > 0 ? stationRow.frames.discarded : ""}</div>
                                                            <div className="row" >{stationRow.frames.recut > 0 ? stationRow.frames.recut : ""}</div>
                                                      </div>
                                                      :
                                                      <div className="eod-table-row">
                                                            <div className="row">{stationRow.stationName}</div>
                                                            {showHours && <div className="row"></div>}
                                                            <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                           {/* <div className="row"> Boxes Made (E): {stationRow.boxesMadeEvents}</div> */}
                                                           <div className="row"> Boxes Made (E): {stationRow.boxesMadeEvents}</div>
                                                            <div className="row"> Boxes Made: {stationRow.boxesMadeSerials ?? 0}</div>
                                                            <div className="row">Inventory: {stationRow.boxInventory}</div>
                                                            { stationRow.boxLabelsVoidedLastInventory && 
                                                                  <div className="row">Adjusted: {stationRow.boxLabelsVoidedLastInventory}</div>
                                                            }
                                                      
                                                      </div>
                                                }
                                                {stationRow.tasks && stationRow.tasks.length > 0 &&
                                                      <div className='eod-header-row'>
                                                            <div> Task </div>
                                                            <div> Name </div>
                                                            <div> Total </div>
                                                      </div>
                                                }
                                                {stationRow.tasks && stationRow.tasks.map(task => {
                                                      return (
                                                            <div style={{ fontSize: "20px", paddingLeft: "20px" }}>
                                                                  <div className="entry">{task.taskDescription} {task.taskName} {task.total} </div>
                                                            </div>
                                                      )
                                                })}
                                          </>

                                    )
                              })}


                              { /**
                                     <div className='eod-header-row'>
                                          <div className="row" >Subtotals</div>
                                          { showHours && <div className="row" >Hours</div> }
                                          <div className="row" >Team Members</div>
                                          <div className="row" >{ data && data.data ? data.data.grandTotals.framesCompleted.premiere : 0}</div>
                                          <div className="row" >Rejected</div>
                                          <div className="row" >Voided</div>
                                          <div className="row" >Recut</div>

                                    </div>
                                     */}
                        </div>
                  </Card>
                  <Card>
                        

                        {error && <p>An error occurred loading the report.</p>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}


                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>DecoSlim</h2>

                        <div>
                              <div className='eod-header-row'>
                                    <div className="row" >Station</div>
                                    {showHours && <div className="row" >Hours</div>}
                                    <div className="row" >Team Members</div>
                                    <div className="row" >Total</div>
                                    <div className="row" >Rejected</div>
                                    <div className="row" >Voided</div>
                                    <div className="row" >Recut</div>

                              </div>
                              {!hasData(data?.data?.decoslim) &&
                                    <div className="text-left">There are no entries for the selected day</div>
                              }
                              {data && data.data && data.data.decoslim && data.data.decoslim.map((stationRow: IStationEntry) => {
                                    return (

                                          <>
                                                {stationRow.stationId !== 5 ?
                                                      <div className="eod-table-row">
                                                            <div className="row" >{stationRow.stationName}</div>
                                                            {showHours && <div className="row">{stationRow.hours && (stationRow.hours.timeAtStation ? DurationUtils.formatDuration(stationRow.hours.timeAtStation) : 0)}</div>}
                                                            <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                            <div className="row" >{stationRow.frames.completed > 0 ? stationRow.frames.completed : ""}</div>
                                                            <div className="row" >{stationRow.frames.rejected > 0 ? stationRow.frames.rejected : ""}</div>
                                                            <div className="row" >{stationRow.frames.discarded > 0 ? stationRow.frames.discarded : ""}</div>
                                                            <div className="row" >{stationRow.frames.recut > 0 ? stationRow.frames.recut : ""}</div>
                                                      </div>
                                                      :
                                                      <div className="eod-table-row">
                                                            <div className="row">{stationRow.stationName}</div>
                                                            {showHours && <div className="row"></div>}
                                                            <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                            { /*<div className="row"> Boxes Made (E): {stationRow.boxesMadeEvents}</div>*/}
                                                            <div className="row"> Boxes Made: {stationRow.boxesMadeSerials  ?? 0 }</div>
                                                            <div className="row">Inventory: {stationRow.boxInventory}</div>
                                                            <div className="row" ></div>
                                                            <div className="row" ></div>
                                                            <div className="row" ></div>
                                                      </div>
                                                }
                                                {stationRow.tasks && stationRow.tasks.length > 0 &&
                                                      <div className='eod-header-row'>
                                                            <div> Task </div>
                                                            <div> Name </div>
                                                            <div> Total </div>
                                                      </div>
                                                }
                                                {stationRow.tasks && stationRow.tasks.map(task => {
                                                      return (
                                                            <div style={{ fontSize: "20px", paddingLeft: "20px" }}>
                                                                  <div className="entry">{task.taskDescription} {task.taskName} {task.total} </div>
                                                            </div>
                                                      )
                                                })}
                                          </>

                                    )
                              })}
                              { /**
                                     <div className='eod-header-row'>
                                          <div className="row" >Subtotals</div>
                                          { showHours && <div className="row" >Hours</div> }
                                          <div className="row" >Team Members</div>
                                          <div className="row" >{ data && data.data ? data.data.grandTotals.framesCompleted.premiere : 0}</div>
                                          <div className="row" >Rejected</div>
                                          <div className="row" >Voided</div>
                                          <div className="row" >Recut</div>

                                    </div>
                                    */}
                        </div>
                  </Card>
                  <Card>
      

                        {error && <p>An error occurred loading the report.</p>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}


                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>Target</h2>

                        <div>
                              <div className='eod-header-row'>
                                    <div className="row" >Station</div>
                                    {showHours && <div className="row" >Hours</div>}
                                    <div className="row" >Team Members</div>
                                    <div className="row" >Total</div>
                                    <div className="row" >Rejected</div>
                                    <div className="row" >Voided</div>
                                    <div className="row" >Recut</div>

                              </div>
                              {!hasData(data?.data?.target) &&
                                    <div className="text-left">There are no entries for the selected day</div>
                              }
                              {data && data.data && data.data.target && data.data.target.map((stationRow: IStationEntry) => {
                                    return (

                                          <>
                                                {stationRow.stationId !== 5 ?
                                                      <div className="eod-table-row">
                                                            <div className="row" >{stationRow.stationName}</div>
                                                            {showHours && <div className="row">{stationRow.hours && (stationRow.hours.timeAtStation ? DurationUtils.formatDuration(stationRow.hours.timeAtStation) : 0)}</div>}
                                                            <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                            <div className="row" >{stationRow.frames.completed > 0 ? stationRow.frames.completed : ""}</div>
                                                            <div className="row" >{stationRow.frames.rejected > 0 ? stationRow.frames.rejected : ""}</div>
                                                            <div className="row" >{stationRow.frames.discarded > 0 ? stationRow.frames.discarded : ""}</div>
                                                            <div className="row" >{stationRow.frames.recut > 0 ? stationRow.frames.recut : ""}</div>
                                                      </div>
                                                      :
                                                      <div className="eod-table-row">
                                                            <div className="row">{stationRow.stationName}</div>
                                                            {showHours && <div className="row"></div>}
                                                            <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                            { /*<div className="row"> Boxes Made (E): {stationRow.boxesMadeEvents}</div>*/}
                                                            <div className="row"> Boxes Made: {stationRow.boxesMadeSerials  ?? 0 }</div>
                                                            <div className="row">Inventory: {stationRow.boxInventory}</div>
                                                            <div className="row" ></div>
                                                            <div className="row" ></div>
                                                            <div className="row" ></div>
                                                      </div>
                                                }
                                                {stationRow.tasks && stationRow.tasks.length > 0 &&
                                                      <div className='eod-header-row'>
                                                            <div> Task </div>
                                                            <div> Name </div>
                                                            <div> Total </div>
                                                      </div>
                                                }
                                                {stationRow.tasks && stationRow.tasks.map(task => {
                                                      return (
                                                            <div style={{ fontSize: "20px", paddingLeft: "20px" }}>
                                                                  <div className="entry">{task.taskDescription} {task.taskName} {task.total} </div>
                                                            </div>
                                                      )
                                                })}
                                          </>

                                    )
                              })}
                              { /**
                                     <div className='eod-header-row'>
                                          <div className="row" >Subtotals</div>
                                          { showHours && <div className="row" >Hours</div> }
                                          <div className="row" >Team Members</div>
                                          <div className="row" >{ data && data.data ? data.data.grandTotals.framesCompleted.premiere : 0}</div>
                                          <div className="row" >Rejected</div>
                                          <div className="row" >Voided</div>
                                          <div className="row" >Recut</div>

                                    </div>
                                     */}
                        </div>
                  </Card>

                  <Card>
                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>Alloy</h2>
                        <div className='eod-header-row'>
                              <div className="row" >Station</div>
                              {showHours && <div className="row" >Hours</div>}
                              <div className="row" >Team Members</div>
                              <div className="row" >Total</div>
                              <div className="row" >Rejected</div>
                              <div className="row" >Voided</div>
                              <div className="row" >Recut</div>

                        </div>
                        {!hasData(data?.data?.alloy) &&
                              <div className="text-left">There are no entries for the selected day</div>
                        }

                        {data && data.data && data.data.alloy && data.data.alloy.map((stationRow: IStationEntry) => {

                              return (

                                    <>
                                          <div className="eod-table-row">
                                                <div className="row" >{stationRow.stationName}</div>
                                                {showHours && <div className="row">{stationRow.hours && (stationRow.hours.timeAtStation ? DurationUtils.formatDuration(stationRow.hours.timeAtStation) : 0)}</div>}
                                                <div className="row" >{stationRow.teamMemberCount ?? 0}</div>
                                                <div className="row" >{stationRow.frames.completed > 0 ? stationRow.frames.completed : ""}</div>
                                                <div className="row" >{stationRow.frames.rejected > 0 ? stationRow.frames.rejected : ""}</div>
                                                <div className="row" >{stationRow.frames.discarded > 0 ? stationRow.frames.discarded : ""}</div>
                                                <div className="row" >{stationRow.frames.recut > 0 ? stationRow.frames.recut : ""}</div>

                                          </div>

                                          {stationRow.tasks && stationRow.tasks.length > 0 &&
                                                <div className='eod-header-row'>
                                                      <div> Task </div>
                                                      <div> Name </div>
                                                      <div> Total </div>
                                                </div>
                                          }
                                          {stationRow.tasks && stationRow.tasks.map(task => {
                                                return (
                                                      <div style={{ fontSize: "20px", paddingLeft: "20px" }}>
                                                            <div className="entry">{task.taskDescription} {task.taskName} {task.total} </div>
                                                      </div>
                                                )
                                          })}
                                    </>

                              )
                        })}
                  </Card>

                  <Card>
                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>Grand Total</h2>

                        <div className='eod-header-row'>
                              <div className="row" ></div>
                              {showHours && <div className="row" >Hours</div>}
                              <div className="row" >Team Members *</div>
                              <div className="row" >Total</div>
                              <div className="row" >Rejected</div>
                              <div className="row" >Voided</div>
                              <div className="row" >Recut</div>
                        </div>

                        <div className="eod-table-row">
                              {data && data.data &&
                                    <>
                                          <div className="row" ></div>
                                          {showHours && <div className="row" ></div>}
                                          <div className="row" >{data.data.grandTotals.totalWorkers}</div>
                                          <div className="row" >{totalCompleted()}</div>
                                          <div className="row" ></div>
                                          <div className="row" ></div>
                                    </>
                              }
                        </div>
                        <div className="flex">* Team members is unique people</div>
                  </Card>

                  <Card>

                  {data && data.data && data.data.ticketStats && ( data.data.ticketStats.createdToday > 0 || data.data.ticketStats.inProcess > 0 || data.data.ticketStats.inProcess > 0 ) &&
                              <> 
                                    <div style= {{display: "flex", fontSize: "40px", backgroundColor:"red", color:"white", justifyContent:"space-between", alignItems:"center"}}> 
                                          <div>Tickets: </div>
                                          <div>Created: {data.data.ticketStats.createdToday}</div>  {/* created today */}
                                          <div>In process: {data.data.ticketStats.inProcess}</div> {/* on the floor */}
                                          <div>To ship: {data.data.ticketStats.toShip}</div>
                                       
                                    </div>
                              </>  
                        }

                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>Returns</h2>

                        <div className='eod-header-row'>
                              <div className="row" >Frame Type</div>
                              <div className="row" >Normal Return</div>
                              <div className="row" >Open Box</div>
                              <div className="row" >Total Returns</div>
                        </div>


                        {data && data.data && data.data.returns && data.data.returns.map((x: IReturnEntry) => {
                              return (

                                    <>
                                          <div className="eod-table-row">
                                                <div className="row" >{x.frameType}</div>
                                                <div className="row" >{x.returnsOther}</div>
                                                <div className="row" >{x.openBoxReturns}</div>
                                                <div className="row" >{x.returnsOther + x.openBoxReturns}</div>
                                          </div>

                                    </>
                              )
                        })}

                        {data && data.data && data.data.returns && (!data.data.returns || (data.data.returns.length && data.data.returns.length === 0)) &&
                              <>
                                    <div className="text-left">There are no entries for the selected day</div>

                              </>
                        }
                  </Card>
                  <Card>

                        <h2 className="text-left" style={{ margin: "0", padding: "0.5rem", backgroundColor: "#1a2a46", color: "white", width: "100%", fontSize: "1.5 rem", fontWeight: "300" }}>Shipping</h2>
                              { data && data.data && data.data.shippedToday && 
                                    <>
                                          <div className='eod-header-row'>
                                                <div className="row" >Premiere</div>
                                                <div className="row" >{data.data.shippedToday.premiere}</div>
                                          </div>
                                          <div className='eod-header-row'>
                                                <div className="row" >Alloy</div>
                                                <div className="row" >{data.data.shippedToday.alloy}</div>
                                          </div>
                                          <div className='eod-header-row'>
                                                <div className="row" >Samples</div>
                                                <div className="row" >{data.data.shippedToday.samples}</div>
                                          </div>
                                    </>

                              }
                  </Card>
                  <Card>
                  { data && data.data && data.data.lastBoxingPrepInventory  && 
                        
                        <div className="text-left">Last Boxing Prep Inventory on {data.data.lastBoxingPrepInventory.date} with a discrepancy of {data.data.lastBoxingPrepInventory.discrepancy}</div>
                  }
                  { data && data.data && data.data.lastFIPInventory.premiere.lastInventoryDate  && 

                        <div className="text-left">Last Premiere FIP Inventory on {data.data.lastFIPInventory.premiere.lastInventoryDate} counted {data.data.lastFIPInventory.premiere.totalFIPs }, voiding {data.data.lastFIPInventory.premiere.voided } and unvoiding {data.data.lastFIPInventory.premiere.unvoided }</div>

                  }
                  { data && data.data && data.data.lastFIPInventory.premiere.lastInventoryDate  && 

                        <div className="text-left">Last DecoSlim FIP Inventory on {data.data.lastFIPInventory.decoslim.lastInventoryDate} counted {data.data.lastFIPInventory.decoslim.totalFIPs }, voiding {data.data.lastFIPInventory.decoslim.voided } and unvoiding {data.data.lastFIPInventory.decoslim.unvoided }</div>

                  }
                  { data && data.data && data.data.lastFIPInventory.alloy.lastInventoryDate && 

                        <div className="text-left">Last Alloy FIP Inventory on {data.data.lastFIPInventory.alloy.lastInventoryDate} counted {data.data.lastFIPInventory.alloy.totalFIPs }, voiding {data.data.lastFIPInventory.alloy.voided } and unvoiding {data.data.lastFIPInventory.alloy.unvoided }</div>

                  }

                  </Card>
            </div>
      )


}

export default EndOfDaySlackReport;