
export enum HistoryEventTypes {
      station_start = 1,  // timer event to start work at a station
      station_pause = 2,  // time event to end work at a station 

      frame_start = 3,  // an event marking the start of work on a frame at a station - this is used by boxing (frame_log ) and alloy boxing

      frame_end = 4, // the task at the station was completed succesfully - frame is now ready to go to the next station

      frame_minus = 5, // NO LONGER USED - now cancels a start event - handful of events in the db but can be ignored ----  deletes the start event - removes the frame as being completed at that station -
      frame_rejected = 6,  // ends a frame_start - some problem was found with the quality of the frame at the station - it will be returned to previous station. this is used by boxing (frame_log ),  alloy boxing and QC
      frame_canceled = 7,  // NOT USED  ends/cancel the 'start' of the frame -- basically used to 'undo' the scanning  of a frame at a station ... this is used by boxing (frame_log ),  alloy boxing and QC

      frame_creation = 8, // used by cutting station to create an event when new frames are created
      frame_creation_manual = 16,
      
      frame_serial_voided = 9,  // this event cannot be timed, since the voiding can be done at a station and at the FIP window

      frame_recut = 10, // reused in boring and cutting - marks the start of a frame acivity and is followed by a frame_end

      frame_recut_resized = 11,

      frame_serial_unvoided = 12,   // this event cannot be timed, since the voiding can be done at a station and at the FIP window

      labels_frame_changed = 13,

      frame_discarded = 14, 

      frame_returned = 15, //neither a start or end - just a stamp on the frame

      //when a batch is started, the timer is not necessarily started and can be stopped/paused as necessary
      // the start time of the batch is recorded in the batch table itself
      batch_end = 17, // when a timer is started it will be stopped/paused when the batch_ends
      batch_canceled = 18,  // when a timer is started it will be stopped/paused when the batch_ends

      task_completed_success = 19,  // plus 1
      task_completed_canceled = 20, //minus 1 (cancels last entry)
  
          // not  used directly in the history event system but rather in the FIP inventory updating process - used for explicit tracking
          fip_inventory_void = 23, // used by the fip inventory to void a frame not found in the inventory - it may exist but in another station but if not unvoided, it supposedly didn't exist
          fip_inventory_unvoid = 24, // used by the fip inventory to unvoid a frame found in the inventory - it may exist 
          fip_inventory_moved = 25, // frame was active in FIPs but moved to new station by inventory
          frame_returned_open_box = 26,
          frame_shipped = 27,

          sent_to_fba = 28, // used to maek a history event entry for frames that where sent to FBA

          converted_b_to_a_stack = 29, /// used when a B Stock (open box) is going to be reboxed and sold as a new frame (a-stock).

          // TICKET EVENTS
          ticket_assigned_to_frame = 30,
          ticket_removed_from_frame = 31,
          ticket_removed_from_voided_frame = 32, 
          ticket_shipped = 33, 
          ticket_deleted = 34,
          ticket_created = 35,

          pending_2025_conversion = 36,
          finished_2025_conversion = 37,
}