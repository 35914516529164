import useFetch from "../../hooks/use-fetch";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import Config from "../../config";
import { GridComponent } from 'echarts/components'
import { BarSeriesOption, EChartsOption, SeriesOption } from "echarts/types/dist/echarts";
import { ReactECharts } from "../../echarts/react-echarts";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import { useLocation } from 'react-router-dom';
import { DurationUtils } from "../../utils/duration-utils";
import { Duration } from "../../models/duration";
import { Panel } from "primereact/panel";
import { DateUtils } from "../../utils/date-utils";
import { Dropdown } from "primereact/dropdown";
import { ListBox, ListBoxChangeEvent } from "primereact/listbox";
import DateRangePicker from "../../date-pickers/date-range-picker"
import { DateTime } from "luxon";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";

interface IUserPerformanceResult {
      stationId: number,
      stationName: string,
      stationFrameType: string;
      entries: IUserPerformance[]
}

interface IUserPerformance {
      firstname: string
      lastname: string
      userId: string;
      totalFramesCompleted: number,
      totalFrameTime: Duration;
      totalStationTime: Duration;
      avgTimePerFrame: Duration;
      avgTimePerFrameStation: Duration;
      stationId: string;
      stationName: string;
      stationFrameType: string;
      date: string;

}

interface IPerformanceChartPerStation {

      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}
interface IUserStationRow {
      userName: string
      stationName: string
      stationFrameType: string;
      percentage: number;
      date: string;

}

interface IAvgFrameTimeAtStation {
      date: string;
      eventHistoryId: string;
      stationName: string;
      eventType: string;
      timeLocal: string;
      avgFrameTime: number;
      totalFrames: number;
      avgUniqueFrameTime: number;
      totalUniqueFrames: number;
      accumulatedStationTime: number,
      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}

interface ISeriesEntry {
      yAxisIndex: number,
      name: string;//'Direct',
      type: string; //'bar',
      stack: string; //'total',
      color: string;
      label: {
            show: true,
            formatter: any //()// {
            //          return "10% (30 frames total) \n xxxxxxxxxxxx"
            //      }
      },
      emphasis: {
            focus: string //'series'
      },
      data: number[] //[320]
}

interface User {
      userId: string,
      userName: string
}

const AvgTimePerFrameCompletionAtStation = () => {
      const location = useLocation()
      const queryParameters = new URLSearchParams(location.search)

      const [eBarChartOptions, setEBarChartOptions] = useState<EChartsOption>()
      const [selectedUsers, setSelectedUsers] = useState<User[]>()
      const [selectedStation, setSelectedStation] = useState<{ stationId: string, stationName: string }[]>()


      const [startDate, setStartDate] = useState<DateTime | null>(DateTime.now().startOf('month'));
      const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

      const [range, setRange] = useState<"day" | "weekly" | "month" | "quarter">("day")



      const url = range && startDate && endDate && `${Config.baseUrl}/api/admin/reports/production/avg-time-per-frame-at-station?startdate=${startDate.toFormat('yyyy-MM-dd')
            + 'T00:00:01'}&enddate=${endDate.toFormat('yyyy-MM-dd') + 'T23:59:59'}`;
      const { data, loading, error } = useFetch<IAvgFrameTimeAtStation[]>(url ?? "", [], {});

      const rangeOptions: string[] = ['day', "week", 'month', "quarter"];

      function hasData(x: any) {
            return x && x.length > 0 ? true : false;
      }

      useEffect(() => {

            function getData() {
                  if (!data) {
                        return
                  }
                  if (data.length === 0) {
                        return
                  }
                  let charts: IPerformanceChartPerStation[] = [];


                  //                  https://coolors.co/bd9971-0267c1-84d2f6-e54b4b-cc5803
                  const colors = {
                        frames: '#bd9971',
                        premiere: "#46201a",
                        alloy: "gray",
                        hours: '#0267C1',
                        averageTimePerDay: '#E54B4B',
                        accumulativeAverage: '#84D2F6'
                  };

                  const days = data.map(e => e.date);
                  const chart = {
                        grid: {
                              left: '19',

                        },
                        tooltip: {
                              trigger: 'axis',
                              axisPointer: {
                                    type: 'cross',
                                    crossStyle: {
                                          color: '#999'
                                    }
                              },
                              formatter: function (params: any) {
                                    const toolTipHtml = `<div style="color:${params[0].color}">${params[0].seriesName}: ${DateUtils.msToTime((params[0].value * 1000))}</div>
                                                             <div style="color:${params[1].color}">${params[1].seriesName}: ${params[1].value}</div>
                                                             <div style="color:${params[2].color}">${params[2].seriesName}: ${DateUtils.msToTime((params[2].value * 1000))}</div>
                                                             <div style="color:${params[3].color}">${params[3].seriesName}: ${params[3].value}</div>                                                                                                                                                                        
                                                            `;

                                    //    <div style="color:${params[4].color}">${params[4].seriesName}: ${DateUtils.msToTime( (params[4].value * 1000))}</div>   

                                    return toolTipHtml;
                              }
                        },
                        toolbox: {
                              feature: {
                                    dataView: { show: true, readOnly: false },
                                    magicType: { show: true, type: ['line', 'bar'] },
                                    restore: { show: true },
                                    saveAsImage: { show: true },
                                    //  dataZoom: {show: true}
                              }
                        },

                        legend: {
                              data: ['avgFrameTime', 'avgUniqueFrameTime', 'timerStart', 'timerEnd'], /*, 'accumulatedStationTime'*/
                              show: true,
                              icon: 'circle',
                              width: "auto",
                              height: "auto",
                              orient: "horizontal",
                              align: "auto",
                              itemWidth: 30, // correct this
                              itemHeight: 10,
                              itemGap: 40,

                        },
                        xAxis: [
                              {
                                    type: 'category',
                                    axisTick: {
                                          alignWithLabel: true
                                    },
                                    data: days
                              },

                        ],
                  
                        dataZoom: [
                              {
                                    id: 'dataZoomX',
                                    type: 'slider',
                                    xAxisIndex: [0],
                                    filterMode: 'filter'
                              },
                              {
                                    id: 'dataZoomY',
                                    type: 'slider',
                                    yAxisIndex: [0],
                                    filterMode: 'empty'
                              }
                        ],
                        /*
                        dataZoom: [
            
                              {
                                    id: 'dataZoomX',
                                    type: 'slider',
                                    xAxisIndex: [0],
                                    filterMode: 'filter',
                                    zoomLock: true,
                                    width: 10,
                                    right: 10,
                                    start: 25,
                                    end: 100,
                                    handleSize: 200,
                              },

                         ],      */
                        yAxis: [

                              {
                                    type: 'value',
                                    position: 'right',
                                    offset: 100,
                                    alignTicks: true,

                              },


                        ],
                        series: [
                              {
                                    name: 'avgFrameTime',
                                    type: 'line',
                                    //     color: colors.frames,
                                    data: data.map(event => {
                                          if (event.eventType === "station_start") {
                                                return null
                                          } else {
                                                return event.avgFrameTime;
                                          }

                                    }),
                                    itemStyle: {
                                          color: "pink"
                                    },
                                    label: {
                                          show: true,
                                          position: 'bottom',
                                          textStyle: {
                                                fontSize: 10
                                          },

                                          formatter: function (params: any) {
                                                let event = data[params.dataIndex];
                                                if (event.eventType === "station_start") {
                                                      return null; //event.date;
                                                } else if (event.eventType === "station_pause") {
                                                      return `${DateUtils.msToTime((params.value * 1000))}\n ${event.totalFrames}`;; //event.date;
                                                } else {
                                                      return `${DateUtils.msToTime((params.value * 1000))}\n ${event.totalFrames}`;
                                                }

                                          }
                                    }
                              },

                              {
                                    /*      yAxisIndex: 0, */
                                    name: 'avgUniqueFrameTime',
                                    type: 'line',
                                    //    color: colors.alloy,
                                    data: data.map(function (event, index) {
                                          if (event.eventType === "station_start") {
                                               // return  data[index + 1].avgUniqueFrameTime
                                               return null;
                                          } else {
                                                return event.avgUniqueFrameTime;
                                          }

                                    }),
                                    stack: 'x'
                              },

                              {

                                    name: 'timerStart',
                                    type: 'scatter',
                                    color: 'green',
                                    //    data: data.map( day=> Math.round( day.totalMinutes / 60 * 100 ) / 100)
                                    data: data.map(function (value, index) {
                                          if (value.eventType === "station_start") {
                                                if (index <= data.length - 1) {
                                                      return data[index + 1].avgUniqueFrameTime
                                                } else {
                                                      return null;
                                                }
                                          }
                                          return null;
                                    }),
                                    label: {
                                          show: true,
                                          position: 'top',
                                          textStyle: {
                                                fontSize: 12
                                          },

                                          formatter: function (params: any) {
                                                let event = data[params.dataIndex];
                                                let dateSplit = event.date.split(' ');
                                                if (event.eventType === "station_start") {
                                                      return `${dateSplit[0]}\n${dateSplit[1]}`;
                                                }

                                          }
                                    }

                              },
                              {

                                    name: 'timerEnd',
                                    type: 'scatter',
                                    color: 'red',
                                    //    data: data.map( day=> Math.round( day.totalMinutes / 60 * 100 ) / 100)
                                    data: data.map(function (value, index) {
                                          console.log(index)
                                          if (value.eventType === 'station_pause') {
                                                return data[index].avgUniqueFrameTime;
                                          } else {
                                                return null;
                                          }
                                    }),
                                    label: {
                                          show: true,
                                          position: 'top',
                                          textStyle: {
                                                fontSize: 12
                                          },

                                          formatter: function (params: any) {
                                                let event = data[params.dataIndex];
                                                let dateSplit = event.date.split(' ');
                                                if (event.eventType === "station_pause") {
                                                      return `${dateSplit[0]}\n${dateSplit[1]}`;
                                                }
                                          }
                                    }
                              },
                              /*       
                              {
         
                                    name: 'accumulatedStationTime',
                                    type: 'line',
                               //     color: colors.averageTimePerDay,                                    
                                 
                                    data: data.map( day=> day.accumulatedStationTime)
                              },
                              */

                        ] as SeriesOption[]
                  } as EChartsOption;

                  setEBarChartOptions(chart);
            }
            getData()

      }, [data]);




      return (
            <div className='report' >

                  <div className="calendar-selector">
                        <div className='flex flex-row pl-5'>

                              <DateRangePicker startDateCallback={(dateString) => {
                                    setStartDate(DateTime.fromISO(dateString));
                              }}
                                    endDateCallback={(dateString) => {
                                          setEndDate(DateTime.fromISO(dateString));
                                    }} />


                              <div className="card flex justify-content-center m-4">
                                    <div className="flex flex-wrap gap-4">
                                          <div style={{ fontSize: "15px", color: "white", fontWeight: "700" }}> Group By:</div>
                                          {rangeOptions && rangeOptions.map(option => {
                                                return (
                                                      <div className="range-radio-options flex align-items-center">
                                                            <RadioButton style={{ color: "white" }} inputId={option} name="range" value={option} onChange={(e: RadioButtonChangeEvent) => setRange(e.value)} checked={range === option} />
                                                            <label htmlFor={option} className="ml-2">{option}</label>
                                                      </div>
                                                )

                                          })}
                                    </div>
                              </div>
                        </div>
                  </div>


                  <Panel className={"body-panel"}>
                        <div className="report-title-wrapper">
                              <div className="report-title">Average Time Per Frame  </div>
                              <div className="report-date-title">{startDate && startDate.toFormat('MMM dd yyyy')} to {endDate && endDate.toFormat('MMM dd yyyy')} </div>
                        </div>

                        {error && <div>An error occurred loading the report.</div>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}

                        {data && data.length === 0 &&
                              <div>No frames were completed on this day</div>
                        }

                        {eBarChartOptions &&

                              <div style={{ width: "100%", height: "500px" }}>
                                    <ReactECharts option={eBarChartOptions} style={{ height: "90%", width: "100%" }} />
                              </div>

                        }






                  </Panel>
            </div>
      )
}

export default AvgTimePerFrameCompletionAtStation;
