import useFetch from "../../hooks/use-fetch";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import Config from "../../config";
import {GridComponent} from 'echarts/components'
import { BarSeriesOption, EChartsOption, SeriesOption } from "echarts/types/dist/echarts";
import { ReactECharts } from "../../echarts/react-echarts";
import NextPreviousDayButtons from "../components/next-previous-day-buttons";
import AverageTimePerFrameChartOptions from "./average-time-per-frame-chart-options";
import { useLocation } from 'react-router-dom';
import { DurationUtils } from "../../utils/duration-utils";
import { Duration } from "../../models/duration";
import { Panel } from "primereact/panel";
import { DateUtils } from "../../utils/date-utils";
import { Dropdown } from "primereact/dropdown";
import { ListBox, ListBoxChangeEvent } from "primereact/listbox";
import DateRangePicker from "../../date-pickers/date-range-picker"
import { DateTime } from "luxon";
import { UserListView } from "./user-list";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { TimePunchEntry } from "./dtos/time-punch-entry.dto";
import { DayUserPunches } from "./dtos/day-user-punches.dto";

interface IUserPerformanceResult {
      stationId: number,
      stationName: string,
      stationFrameType: string;
      entries: IUserPerformance[]
}

interface IUserPerformance {
      firstname: string
      lastname: string
      userId: string;
      totalFramesCompleted: number,
      totalFrameTime: Duration;
      totalStationTime: Duration;
      avgTimePerFrame: Duration;
      avgTimePerFrameStation: Duration;
      stationId: string;
      stationName: string;
      stationFrameType: string;
      date: string;

}

interface IPerformanceChartPerStation {

      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}
interface IUserStationRow {
      userName: string
      stationName: string
      stationFrameType: string;
      percentage: number;
      date: string;

}

interface IDailyAverage {
      date: string; 
      totalFrames: number;
      totalPremiere: number;
      totalAlloy: number;
      totalMinutes: number;
      totalHoliday: number;
      totalPto: number;
      totalWork: number;
      accumulativeFrames: number;
      accumulativeMinutes: number;
      eBarChartOptions: EChartsOption;
      rawData: IUserPerformance[];
      //  entries: IUserStationRow[];
}

interface ISeriesEntry {
      yAxisIndex: number, 
      name: string;//'Direct',
      type: string; //'bar',
      stack: string; //'total',
      color: string;
      label: {
            show: true,
            formatter: any //()// {
            //          return "10% (30 frames total) \n xxxxxxxxxxxx"
            //      }
      },
      emphasis: {
            focus: string //'series'
      },
      data: number[] //[320]
}

interface User{
      userId: string,
      userName: string
}
interface UserData{
      userId: string,
      userData: IDailyAverage[] | null
}
const PaychexTimeVersusApp = () => {
      const location = useLocation()
      const queryParameters = new URLSearchParams(location.search)

      const [eBarChartOptions, setEBarChartOptions] = useState<EChartsOption>()
      const [selectedUsers, setSelectedUsers] = useState<User[]>()
      const [selectedStation, setSelectedStation] = useState<{stationId: string, stationName: string}[]>()

      const [userExcludeList, setUserExcludeList] = useState<User[]>([] );
      const [exludeUserIdParams, setExcludeUserIdParams] = useState<string>( "" );

      const [startDate, setStartDate] = useState<DateTime | null>( DateTime.now().startOf('month') );
      const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

      const [range, setRange] = useState< "day" | "weekly" | "month" | "quarter" >("day")

      
      
 //'     const url = range && startDate && endDate && `${Config.baseUrl}/api/admin/paychex/get-user-time-punches?empidentifier=${153}&startdate=${startDate.toFormat('yyyy-MM-dd') + 'T00:00:01'}&enddate=${endDate.toFormat('yyyy-MM-dd') + 'T23:59:59'}`;
//189 - 2025-03-10
      const url = range && startDate && endDate && `${Config.baseUrl}/api/admin/paychex/get-user-time-punches?empidentifier=${189}&startdate=${ '2025-03-10T00:00:01'}&enddate=${ '2025-03-10T23:59:59'}`;
                                                            //&range=${range}${exludeUserIdParams}`;
      const { data, loading, error } = useFetch<DayUserPunches[]>(url ?? "", [], {});

      const rangeOptions: string[] = ['day', "week", 'month', "quarter"];

      function hasData(x: any) {
            return x && x.length > 0 ? true : false;
      }

      useEffect(() => {

            function getData() {
                  if (!data) {
                        return
                  }
                  if (data.length === 0) {
                        return
                  }
               //   let charts: IPerformanceChartPerStation[] = [];

  
//                  https://coolors.co/bd9971-0267c1-84d2f6-e54b4b-cc5803
                  const colors = { 
                                    none: "#eaeaea"
                  };

                  const colorPayChex:{[key: string]: string} = {                
                    
                         "Work": "green",
                        "Break": "pink" ,
                        "Holiday": "red" 

                  };

                  const days = data.map( e=> e.date);
                  const hours = ["5:00am", "6:00AM", "7:00AM", "8:00AM", "9:00AM","10:00AM" ,"11:00AM","12:00PM", "1:00PM", "2:00PM", "3:00PM", "4:00PM", "5:00PM", "6:00PM"];
/*
                              formatter: function (params: any) {
                                    const toolTipHtml =     `<div style="color:${params[0].color}">${params[0].seriesName}: ${params[0].value}</div>
                                                             <div style="color:${params[1].color}">${params[1].seriesName}: ${params[1].value}</div>
                                                             <div style="color:${params[2].color}">${params[2].seriesName}: ${params[2].value}</div>
                                                             <div style="color:${params[3].color}">${params[3].seriesName}: ${DateUtils.toHHMMSS_string( (params[3].value * 60))}</div>
                                                             <div style="color:${params[4].color}">${params[4].seriesName}: ${params[4].value}</div>   
                                                             <div style="color:${params[5].color}">${params[5].seriesName}: ${params[5].value}</div>                                                         
                                                            `;
                                                            
                                    return toolTipHtml;
                              }*/
                  const chart = {
                        tooltip: {
                              trigger: 'axis',
                              axisPointer: {
                                    type: 'cross',
                                    crossStyle: {
                                      color: '#999'
                                    }
                              },

                        },
                        grid: {
                              left: '3%',
                              right: '4%',
                              bottom: '3%',
                              containLabel: true
                         },
                        xAxis:[
                            
                              { 
                                    type: 'value',
                                    axisLabel: {
                                          formatter: function (value, index) {
                                                // Formatted to be month/day; display year only in the first label
                                                if ( value === 0 ){
                                                      return "5:00am";
                                                }
                                                let startDay = 5 * 60; //5 am in minutes
                                                let hoursX = startDay + value
                                                let h = Math.floor( hoursX / 60 );
                                                let m = (startDay + value ) - (h * 60);

                                                let amPm = "AM";
                                                if ( h >= 12 ) {
                                                      amPm = "PM"
                                                }
                                                if ( h > 12 ) {
                                                      h = h - 12;
                                                }
                                                
                                                return `${h}:${String(m).padStart(2, '0')}${amPm}`;
                                          // return hours[hoursx];
                                                                              
                                          },

                                    },
                                    axisTick: {
                                         interval: 0
                                    }
                              },

                              {
                                    type: 'time',
                                    boundaryGap: false,
                                    // prettier-ignore
                                    data: hours
                                  },
                  

                        ],
                        yAxis: {
                              type: 'category',
                              data: ['Mon']
                        },
                        series: [] as SeriesOption[]
                              /*
                              {
                                    name: 'Work',
                                    type: 'bar',
                                    stack: 'total',
                                    label: {
                                      show: true
                                    },
                                    emphasis: {
                                      focus: 'series'
                                    },
                                    data: [320]
                                  },
                              */


                  } as EChartsOption;
                  chart.series = [];

                  for ( let day of data) {
                        // pad so the time starts at 5:00am and ends at 6pm so whole day can be viewed.
                        if ( day.punches && day.punches.length > 0 ) {
                              const date =  new Date(day.punches[0].inTime );
                              if (date.getHours()> 5 && date.getMinutes() > 0 ) {
                                    let startTime = new Date( date.getFullYear(), date.getMonth(), date.getDate(), 5, 0, 0);
                                    let minutes = (date.getTime() - startTime.getTime()) / 1000 / 60;
                                    if ( minutes > 0 ) {
                                          chart.series.push( {
                                                name: "none",
                                                type: 'bar',
                                                stack: 'timeline',
                                                label: {
                                                      show: false,
                                                     
                                                },
                                      
                                                itemStyle: {
                                                      color: colors.none
                                                },
                                                emphasis: {
                                                      focus: 'series',
                                                },
                                                data: [ minutes ]
            
                                          }  as SeriesOption )
                                    }
                              }     
                        }
                        for ( let timePunch of day.punches ) {
                              const date =  new Date(day.punches[0].inTime );

                              chart.series.push( {
                                    name: timePunch.payTypeName,
                                    type: 'bar',
                                    stack: 'timeline',
                                    label: {
                                          show: true,
                                          formatter: function(d) {
                                                let data = day.punches[d.componentIndex-1]
                                                const start =  new Date(data.inTime );
                                                const end =  new Date(data.outTime );
                                                const duration = end.getTime() - start.getTime();
                                                const durString = DateUtils.msToTime( duration, true);
                                                let temp =  `FROM: ${data.inTime.substring(10)}\nTO:${data.outTime.substring(10)}\n${durString}`;

                                                return temp;
                                          }
                                    },
                                    itemStyle: {
                                              
                                          color: colorPayChex[timePunch.payTypeName]
                                    
                                    },
                                    emphasis: {
                                          focus: 'series',
                                    },
                                    data: [timePunch.regularMinutes]

                              } as TimePunchEntry )
                        }
                        if ( day.punches && day.punches.length > 0 ) {
                              let lastPunch = day.punches[day.punches.length - 1]
                              let date = new Date( lastPunch.outTime );
                              if (date.getHours()<18) {
                                    let endTime = new Date( date.getFullYear(), date.getMonth(), date.getDate(), 18, 0, 0);
                                    let minutes = (endTime.getTime() - date.getTime()) / 1000 / 60;
                                    if ( minutes > 0 ) {
                                          chart.series.push( {
                                                name: "none",
                                                type: 'bar',
                                                stack: 'timeline',
                                                label: {
                                                      show: false,
                                                     
                                                },
                                      
                                                itemStyle: {
                                                      color: colors.none
                                                },
                                                emphasis: {
                                                      focus: 'series',
                                                },
                                                data: [ minutes ]
            
                                          }  as SeriesOption )
                                    }
                              }     
                        }
                  


                  }

                  setEBarChartOptions(chart);
            }
            getData()

      }, [data]);



      return (
            <div className='report' >
                  
                  <div className="calendar-selector">
                        <div className='flex flex-row pl-5'>

                              <DateRangePicker startDateCallback={(dateString)=> {
                                                                        setStartDate( DateTime.fromISO( dateString ));
                                                                  }} 
                                                endDateCallback={(dateString) => {
                                                      setEndDate( DateTime.fromISO( dateString ));
                                                 }} />


                              <div className="card flex justify-content-center m-4">
                                    <div className="flex flex-wrap gap-4">
                                          <div style={{fontSize:"15px", color:"white", fontWeight:"700"}}> Group By:</div>                              
                                    { rangeOptions && rangeOptions.map ( option => {
                                          return (
                                          <div className="range-radio-options flex align-items-center">
                                                <RadioButton style={{color:"white"}} inputId={option} name="range" value={option} onChange={(e: RadioButtonChangeEvent) => setRange(e.value)} checked={range === option } />
                                                <label htmlFor={option} className="ml-2">{option}</label>
                                          </div>
                                          )

                                    })}
                                    </div>
                        </div>
                        </div>
                  </div>


                  <Panel className={"body-panel"}>
                        <div className="report-title-wrapper">
                              <div className="report-title">Time Logged vs. deconet</div>  
                              <div className="report-date-title">{startDate && startDate.toFormat('MMM dd yyyy')} to {endDate && endDate.toFormat('MMM dd yyyy') } </div>                      
                        </div>

                        {error && <div>An error occurred loading the report.</div>}

                        {loading && <div> <ProgressSpinner /> Loading...</div>}

                        {data && data.length === 0 &&
                              <div>No frames were completed on this day</div>
                        }

                        {eBarChartOptions && 

                              <div style={{ width: "100%", height:"500px"}}>
                                    <ReactECharts option={eBarChartOptions} style={{height: "90%", width: "100%"}}/>
                              </div>

                        }

                  </Panel>
            </div>
      )
}

export default PaychexTimeVersusApp;
