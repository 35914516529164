import React, { Fragment, useCallback, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


import { ReactEChartsProps } from './echarts/react-echarts';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Link, RouterProvider, createBrowserRouter } from 'react-router-dom';
import BoxingPrepActivityReport from './reports/boxing-prep/boxing-prep-activity-report/boxing-prep-activity-report';
import ErrorPage from './error-page';
import DailyActivityReport from './reports/daily-summary-report/daily-activity.report';
import FIPByStyleSizeYear from './reports/fip-by-style-size-year/fip-by-style-size-year';
import EndOfDaySlackReport from './reports/end-of-day-slack/end-of-day-slack-report';
import FBAPicklist from './reports/fba-picklist/fba-picklist.report';
import Layout from './layout';
import PalletInventorySummaryReport from './reports/pallet-inventory/pallet-inventory-summary-report';
import PalletInventorySelection from './reports/pallet-inventory/pallet-inventory-selection';
import WasteReport from './reports/waste-report/waste-report';
import BoxingPrepLastInventoryReport from './reports/boxing-prep/boxing-prep-last-inventory-report/boxing-prep-last-inventory-report';
import BoxingPrepCurrentInventoryReport from './reports/boxing-prep/boxing-prep-current-inventory-level-report copy/boxing-prep-current-inventory-level-report';
import FramesBoxedReport from './reports/frames-boxed-report/frames-boxes-report';
import CutListHistoryHome from './reports/cut-list-history/cut-list-history-home';
import CutListHistorySyncReport from './reports/cut-list-history/cut-list-history-sync-report';
import ProtectedRoute from './auth/protected-route';
import { useSessionContext } from './contexts/session-context';
import { ProtectedRouteProps } from './auth/protected-route-props';
import Login from './auth/login';
import FrameHistoryRoot from './reports/frame-history/frame-history-root';
import ConsumptionMappingReport from './reports/consumption-mapping/consumption-mapping-report';
import StationProductivityReport from './reports/station-productivity-report/station-productivity-report';
import FrameInventoryReport from './reports/frame-inventory-report/frame-inventory-report';
import FrameInventoryBatchReport from './reports/frame-inventory-report/frame-inventory-batch-report';
import UserProductivityReport from './reports/user-productivity-report/user-productivity.report';
import PalletInventoryListReportSelectBatch from './reports/current-pallets-report/pallet-inventory-list-report-selection';
import PalletInventoryListReport from './reports/current-pallets-report/pallet-inventory-list-report';
import AverageTimePerFrameReport from './reports/average-time-per-frame/average-time-per-frame.report';
import { Route, Routes } from "react-router-dom";
import LeftSidebar from './sidebar/left-side-bar';
import Main from './main/main';
import Dashboard from './dashboard/dashboard';
import Settings from './dashboard/settings';
import Pages from './dashboard/pages';
import classNames from 'classnames';
import AvgTimePerFrameCompletionAtStation from './reports/avg-time-at-station/avg-time-per-frame-completion-at-station';
import PaychexTimeVersusApp from './reports/paychex-time-versus-app/paychex-time-versus-app';
import RejectionsReport from './reports/rejections-report/rejections-report';

const option: ReactEChartsProps["option"] = {
      dataset: {
            source: [
                  ["Commodity", "Owned", "Financed"],
                  ["Commodity 1", 4, 1],
                  ["Commodity 2", 2, 4],
                  ["Commodity 3", 3, 6],
                  ["Commodity 4", 5, 3],
            ],
      },
      tooltip: {
            trigger: "axis",
            axisPointer: {
                  type: "shadow",
            },
      },
      legend: {
            data: ["Owned", "Financed"],
      },
      grid: {
            left: "10%",
            right: "0%",
            top: "20%",
            bottom: "20%",
      },
      xAxis: {
            type: "value",
      },
      yAxis: {
            type: "category",
      },
      series: [
            {
                  type: "bar",
                  stack: "total",
                  label: {
                        show: true,
                  },
            },
            {
                  type: "bar",
                  stack: "total",
                  label: {
                        show: true,
                  },
            },
      ],
}

function App() {

      const [isSmallScreenWidth, setIsSmallScreenWidth] = useState(false);
      const [showSideBar, setShowSideBar] = useState(true);
      const [sessionContext, updateSessionContext] = useSessionContext();

      const [isLeftSidebarCollapsed, setIsLeftSidebarCollapsed] =
            useState<boolean>(false);
      const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

      useEffect(() => {
            const updateSize = () => {
                  setScreenWidth(window.innerWidth);
                  if (window.innerWidth < 768) {
                        setIsLeftSidebarCollapsed(true);
                  }
                  if (window.innerWidth > 768) {
                        setIsLeftSidebarCollapsed(false);
                  }
            };
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
      }, []);


      /*
      const setRedirectPath = (path: string) => {
        updateSessionContext({...sessionContext, redirectPath: path});
      }
      */
      const displaySideBarMenu = useCallback((show: boolean) => {
            setShowSideBar(show)
      }, []);

      const setRedirectPath = useCallback((path: string) => {
            updateSessionContext({ ...sessionContext, redirectPath: path });
      }, [sessionContext, updateSessionContext]);

      useEffect(() => {
            if (!sessionContext.redirectPath) {
                  setRedirectPath('somewhere');
            }
      }, [sessionContext, setRedirectPath]);


      const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
            isAuthenticated: !!sessionContext.isAuthenticated,
            authenticationPath: '/login',
            redirectPath: sessionContext.redirectPath,
            setRedirectPath: setRedirectPath
      };

      const [windowSize, setWindowSize] = useState(() => window.innerWidth);

      const resizeHandler = () => setWindowSize(() => window.innerWidth);

      const debounceFunction = (cb: any, delay: any) => {
            let timer: string | number | NodeJS.Timeout | undefined;
            return () => {
                  if (timer) clearTimeout(timer);
                  timer = setTimeout(() => cb(), delay);
            };
      };

      /*
      const debounce = debounceFunction(resizeHandler, 100);

      
      useEffect(() => {
            window.addEventListener("resize", debounce);
            return () => window.removeEventListener("resize", debounce);
      }, []);

      useEffect(() => {
            if (windowSize < 1140) {
                  displaySideBarMenu(false);
                  setIsSmallScreenWidth(true);
            } else {
                  displaySideBarMenu(true);
                  setIsSmallScreenWidth(false);
            }
      }, [windowSize])
      */
      /*
       const router = createBrowserRouter(
             [{
                   path: "/",
                   element: <Layout visible={showSideBar} isSmallScreenWidth={isSmallScreenWidth} displaySideBarMenu={displaySideBarMenu}  />,
                   errorElement: <ErrorPage />,
 
                   children: [
                         { path: "login", element: <Login/> },
                         { path: "cut-list-history", element: <CutListHistoryHome /> },
                         { path: "cut-list-history/batch/:datetime", element: <CutListHistorySyncReport /> },
                         { path: "daily-activity", element: <DailyActivityReport /> },
                         { path: "fip-by-style-size-year", element: <FIPByStyleSizeYear /> },
                         { path: "end-of-day", element: <EndOfDaySlackReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar} /> },
                         { path: "boxing-prep-last-inventory", element: <BoxingPrepLastInventoryReport /> },
                         { path: "boxing-prep-activity", element: <BoxingPrepActivityReport /> },
                         { path: "boxing-prep-current-inventory", element: <BoxingPrepCurrentInventoryReport /> },
                         { path: "pallet-inventory", element: <PalletInventorySelection /> },
                         { path: "pallet-inventory-batch/:batchid", element: <PalletInventorySummaryReport /> },
                         { path: "pallet-inventory-current/", element: <PalletInventorySummaryReport /> },
                         { path: "waste-report", element: <WasteReport/> },
                         { path: "frame-history", element: <FrameHistoryRoot/> },
 //                        { path: "fba-picklist", element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={<ErrorPage />}></ProtectedRoute>  }
                         { path: "fba-picklist", element: <ProtectedRoute {...defaultProtectedRouteProps} outlet={ <FBAPicklist />}></ProtectedRoute>},
                         { path: "deco-net-mapping", element: <ConsumptionMappingReport/>  },
                         { path: "station-productivity-report", element: <StationProductivityReport/>  },
                         { path: "frames-boxed-report", element: <FramesBoxedReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar}/> },
                         { path: "frames-inventory-report", element: <FrameInventoryReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar}/> },
                         { path: "frames-inventory-batch/:batchid/:frameType", element: <FrameInventoryBatchReport /> },
                         { path: "user-productivity-report/", element: <UserProductivityReport /> },
                         { path: "average-time-per-frame/", element: <AverageTimePerFrameReport /> },
                         { path: "pallet-inventory-list-report/", element: <PalletInventoryListReportSelectBatch /> },
                         { path: "pallet-inventory-list-batch/:batchid", element: <PalletInventoryListReport /> },
                   ],
 
             }],
             {
                   basename: "",
             }
       )
 
             ;
        
       return (
             <div className="App">   
                   <RouterProvider router={router} />
             </div>
       );
       */
      const menuHeaderRenderer = (item:any, options:any) => (
             <li key={item.label} className="sidenav-nav-item">
                        <Link className="sidenav-nav-link-header" to={item.routerLink}>
                              <i className={classNames({"sidenav-link-icon": true, [item.icon]: true, }) } style={{ fontSize: '1.5rem' }}></i>
                              {!isLeftSidebarCollapsed && (
                                    <span className="sidenav-link-text">{item.label}</span>
                              )}

                              <b  className={classNames({"caret": true, "rotate-caret": options.active, }) }></b>
                            

                        </Link>
                  </li>
        );

        const itemRenderer = (item:any, options:any) => (
            <li key={item.label} className="sidenav-nav-item">
                       <Link className="sidenav-nav-link" to={item.routerLink}>

                             <i className={classNames({"sidenav-link-icon": true, [item.icon]: true, })}></i>

                             {!isLeftSidebarCollapsed && (
                                   <span className="sidenav-link-text">{item.label}</span>
                             )}

                       </Link>
                 </li>
       );


      const menuItems = [
            {
                  label: 'Production',
                  icon: 'pi pi-fw pi-building',
                  template: menuHeaderRenderer,
                  items: [
                        {
                              label: 'Cut List History',
                              icon: 'pi pi-cog',
                              routerLink: "/cut-list-history",
                              template: itemRenderer,
                        },
                        {
                              label: 'FIPs by Style, Size & Year',
                              icon: 'pi pi-fw pi-th-large',
                              routerLink: "/fip-by-style-size-year",
                              template: itemRenderer,
                        },
                        {
                              label: 'FBA Picklist',
                              icon: 'pi pi-fw pi-list',
                              routerLink: "/fba-picklist",
                              template: itemRenderer,
                        },
                        {
                              label: 'Frame Parts',
                              icon: 'pi pi-fw pi-sitemap',
                              routerLink: "/deco-net-mapping",
                              template: itemRenderer,
                        },
                        {
                              label: 'Frames Boxed',
                              icon: 'pi pi-fw pi-box',
                              routerLink: "/frames-boxed-report",
                              template: itemRenderer,
                        },
                        {
                              label: 'Waste Report',
                              icon: 'pi pi-fw pi-trash',
                              routerLink: "/waste-report",
                              template: itemRenderer,
                        },
                        {
                              label: 'End Of Day',
                              icon: 'pi pi-fw pi-calculator',
                              routerLink: "/end-of-day",
                              template: itemRenderer,
                        },
                        {
                              label: 'Frame Lookup',
                              icon: 'pi pi-fw pi-images',
                              routerLink: "/frame-history",
                              template: itemRenderer,
                        },
                        {
                              label: 'Rejections Report',
                              icon: 'pi pi-fw pi-asterisk',
                              routerLink: "/rejections",
                              template: itemRenderer,
                        },

                  ]
            },
            {
                  label: 'Inventory',
                  icon: 'pi pi-fw pi-clipboard',
                  template: menuHeaderRenderer,
                  items: [
                        {
                              label: 'Pallet Inventory',
                              icon: 'pi pi-fw pi-palette',
                              routerLink: "/pallet-inventory",
                              template: itemRenderer,
                        },
                        {
                              label: 'Frame Inventory',
                              icon: 'pi pi-fw pi-images',
                              routerLink: "/frames-inventory-report",
                              template: itemRenderer,
                        },
                        {
                              label: 'Boxing Prep Inventory',
                              icon: 'pi pi-fw pi-box',
                              routerLink: "/boxing-prep-current-inventory",
                              template: itemRenderer,
                        },
                        {
                              label: 'Pallet Inventory List',
                              icon: 'pi pi-fw pi-clipboard',
                              routerLink: "/pallet-inventory-list-report",
                              template: itemRenderer,
                        },

                  ]
            },
            {
                  label: 'Productivity',
                  icon: 'pi pi-fw pi-chart-line',
                  template: menuHeaderRenderer,
                  items: [
                        {
                              label: 'Daily Activity',
                              icon: 'pi pi-fw pi-calendar',
                              routerLink: "/daily-activity",
                              template: itemRenderer,
                        },
                        {
                              label: 'End Of Day',
                              icon: 'pi pi-fw pi-calendar',
                              routerLink: "/end-of-day",
                              template: itemRenderer,
                        },

                        {
                              label: 'Frame Lookup',
                              icon: 'pi pi-fw pi-barcode',
                              routerLink: "/frame-history",
                              template: itemRenderer,
                        },

                        {
                              label: 'Station Productivty Report',
                              icon: 'pi pi-fw pi-images',
                              routerLink: "/station-productivity-report",
                              template: itemRenderer,
                        },
                        {
                              label: 'Frames Boxed',
                              icon: 'pi pi-fw pi-box',
                              routerLink: "/frames-boxed-report",
                              template: itemRenderer,
                        },
                        {
                              label: 'User Performance',
                              icon: 'pi pi-fw pi-chart-bar',
                              routerLink: "/user-productivity-report",
                              template: itemRenderer,
                        },
                        {
                              label: 'Avg Time Per Frame',
                              icon: 'pi pi-fw pi-chart-line',
                              routerLink: "/average-time-per-frame",
                              template: itemRenderer,
                        },
                        {
                              label: 'Avg Frame at Station',
                              icon: 'pi pi-fw pi-chart-line',
                              routerLink: "/avg-frame-time-at-station",
                              template: itemRenderer,
                        },
                        {
                              label: 'Time Logged versus App',
                              icon: 'pi pi-fw pi-chart-line',
                              routerLink: "/timelogged-versus-app-time",
                              template: itemRenderer,
                        },
      
                  ]
            },

      ];

      const setSidebarCollapsed = ( value: boolean) => {
            setIsLeftSidebarCollapsed( value );
      };
      
      return (
            <Fragment>
                  <LeftSidebar
                        isLeftSidebarCollapsed={isLeftSidebarCollapsed}
                        changeIsLeftSidebarCollapsed={(value) => setIsLeftSidebarCollapsed(value)}
                        menuItems={menuItems}
                  />
                  <Routes>
                        <Route
                              element={
                                    <Main
                                          screenWidth={screenWidth}
                                          isLeftSidebarCollapsed={isLeftSidebarCollapsed}
                                    />
                              }
                        >
                              <Route path="" element={<Dashboard />} />
                              <Route path="/settings" element={<Settings />} />
                              <Route path="/pages" element={<Pages />} />
                              <Route path="login" element={<Login />} />
                              <Route path="cut-list-history" element={<CutListHistoryHome />} />
                              <Route path="cut-list-history/batch/:datetime" element={<CutListHistorySyncReport />} />
                              <Route path="daily-activity" element={<DailyActivityReport />} />
                              <Route path="fip-by-style-size-year" element={<FIPByStyleSizeYear />} />
                              <Route path="end-of-day" element={<EndOfDaySlackReport changeIsLeftSidebarCollapsed={(value) => setIsLeftSidebarCollapsed(value)} isLeftSidebarCollapsed={isLeftSidebarCollapsed} />} />
                              <Route path="boxing-prep-last-inventory" element={<BoxingPrepLastInventoryReport />} />
                              <Route path="boxing-prep-activity" element={<BoxingPrepActivityReport />} />
                              <Route path="boxing-prep-current-inventory" element={<BoxingPrepCurrentInventoryReport />} />
                              <Route path="pallet-inventory" element={<PalletInventorySelection />} />
                              <Route path="pallet-inventory-batch/:batchid" element={<PalletInventorySummaryReport />} />
                              <Route path="pallet-inventory-current/" element={<PalletInventorySummaryReport />} />
                              <Route path="waste-report" element={<WasteReport />} />
                              <Route path="frame-history" element={<FrameHistoryRoot />} />
                              <Route path="fba-picklist" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<FBAPicklist />}></ProtectedRoute>} />
                              <Route path="deco-net-mapping" element={<ConsumptionMappingReport />} />
                              <Route path="station-productivity-report" element={<StationProductivityReport />} />
                              <Route path="frames-boxed-report" element={<FramesBoxedReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar} />} />
                              <Route path="frames-inventory-report" element={<FrameInventoryReport showSideBarMenu={displaySideBarMenu} isMenuVisible={showSideBar} />} />
                              <Route path="frames-inventory-batch/:batchid/:frameType" element={<FrameInventoryBatchReport />} />
                              <Route path="user-productivity-report/" element={<UserProductivityReport />} />
                              <Route path="average-time-per-frame/" element={<AverageTimePerFrameReport />} />
                              <Route path="timelogged-versus-app-time/" element={<PaychexTimeVersusApp/>} />
                              <Route path="avg-frame-time-at-station/" element={<AvgTimePerFrameCompletionAtStation />} />
                              <Route path="pallet-inventory-list-report/" element={<PalletInventoryListReportSelectBatch />} />
                              <Route path="pallet-inventory-list-batch/:batchid" element={<PalletInventoryListReport />} />
                              <Route path="rejections/" element={<RejectionsReport />} />
                        </Route>
                  </Routes>
            </Fragment>
      );
}

//                        //                        <Route path="fba-picklist" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ErrorPage />}></ProtectedRoute>  } 
export default App;
