import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { DateTime } from "luxon";
import { Panel } from "primereact/panel";
import DateRangePicker from "../../date-pickers/date-range-picker";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { TreeTable } from "primereact/treetable";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from 'primereact/button';

interface RejectionComment {
      comment: string;
      count: number;
}
interface RejectionReason {
      reason: string;
      count: number;
      comments: RejectionComment[];
}

interface RejectionEntry {
      station: string;
      count: number;
      reasons: RejectionReason[]
}

const RejectionsReport = () => {

      const [startDate, setStartDate] = useState<DateTime | null>( DateTime.now().startOf('month') );
      const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now());

      const [range, setRange] = useState< "day" | "weekly" | "month" | "quarter" >("day");

      const [showListView, setShowListVIew] = useState<Boolean>( false );

      const rangeOptions: string[] = ['day', "week", 'month', "quarter"];

      const url = range && startDate && endDate && `${Config.baseUrl}/api/admin/reports/production/rejections?startdate=${startDate.toFormat('yyyy-MM-dd') 
                                                            + 'T00:00:01'}&enddate=${endDate.toFormat('yyyy-MM-dd') + 'T23:59:59'}&range=${range}`;

      const { data, loading, error } = useFetch<RejectionEntry[]>(url ?? "", [], {});

        return (
                  <div className='report' >
                        
                        <div className="calendar-selector">
                              <div className='flex flex-row pl-5'>
      
                                    <DateRangePicker startDateCallback={(dateString)=> {
                                                                              setStartDate( DateTime.fromISO( dateString ));
                                                                        }} 
                                                      endDateCallback={(dateString) => {
                                                            setEndDate( DateTime.fromISO( dateString ));
                                                       }} />

                              </div>
                        </div>
      
      
                        <Panel className={"body-panel"}>
                              <div className="report-title-wrapper">
                                    <div className="report-title">Rejections Report</div>  
                                    <div className="report-date-title">{startDate && startDate.toFormat('MMM dd yyyy')} to {endDate && endDate.toFormat('MMM dd yyyy') } </div>                      
                              </div>
      
                              {error && <div>An error occurred loading the report.</div>}
      
                              {loading && <div> <ProgressSpinner /> Loading...</div>}
      
                              {data && data.length === 0 &&
                                    <div>No rejections were found during this period.</div>
                              }

                              <Accordion multiple activeIndex={[0]}>
                                    {data && data.map(rejectionEntry => {
                                          return (
                                                <AccordionTab header={<div><span className="text-lg">{rejectionEntry.station} </span> <span className="text-gray-500">(Total Rejections:{(rejectionEntry.count)})</span></div>}>

                                                      <p className="m-0">
                                                            <Accordion multiple activeIndex={[0]}>
                                                                  {rejectionEntry.reasons && rejectionEntry.reasons.map( reasons => {
                                                                        return (
                                                                              <AccordionTab header={<div>{reasons.reason} ({reasons.count})</div>}>
                                                                 
                                                                                    <ol>

                                                                                          { reasons.comments && reasons.comments.map( comments => {
                                                                                                      return (
                                                                                                            <li className="pb-1">{comments.comment} ({comments.count})</li>
                                                                                                      )
                                                                                                })
                                                                                          }
                                                                                         </ol>
                                                                       
                                                                              </AccordionTab>
                                                                        )
                                                                  })}
                                                            </Accordion>
                                                      </p>

                                                </AccordionTab>
                                          )
                                    })}
                              </Accordion>
                        </Panel>
                  
                        <Panel className={"body-panel mt-2"}>

                              <Button label="Show copiable list" onClick={() => { console.log( showListView); setShowListVIew( !showListView ); }} />

                              { showListView === true && data && data.map( rejectionEntry => {
                                    return (
                                          <div>
                                                <br/>
                                                <div className="font-bold text-xl pt-2">{rejectionEntry.station}</div>
                                                {rejectionEntry.reasons && rejectionEntry.reasons.map( reasons => {
                                                      return (
                                                            <>
                                                            <div className="text-lg font-medium pl-2" style={ {whiteSpace:"pre-wrap"}}> Reason: {reasons.reason} ({reasons.count})</div>          
                                                            &ensp;{ reasons.comments && reasons.comments.map( comments => {
                                                                        return (
                                                                              <div className="pl-4">{comments.comment} ({comments.count})</div>
                                                                        )
                                                                  })
                                                            }
                                                            <br/>
                                                            </>
                                                      )
                                                })}

                                          </div>
                                    )
                              })}
                        </Panel>
                  </div>
        )
}
/*
                                
*/                                          
export default RejectionsReport;