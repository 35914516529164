import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import useFetch from "../../hooks/use-fetch";
import Config from "../../config";
//import NextPreviousDayButtons from "../../components/next-previous-day-buttons";
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { PalletCountInventoryLookupResult } from "./dtos/pallet-count-inventory-iookup-result.dtos";
import "@glideapps/glide-data-grid/dist/index.css";

import useColumnSort from "@glideapps/glide-data-grid"
import useMoveableColumns from "@glideapps/glide-data-grid"

import {
      DataEditor,
      GridCell,
      GridCellKind,
      GridColumn,
      GroupHeaderClickedEventArgs,
      HeaderClickedEventArgs,
      Item
    } from "@glideapps/glide-data-grid";
import { Dropdown } from "primereact/dropdown";



interface IPalletInventorySummaryReport {
      inventoryId: string
      date: string;
}

interface TableRow {
      mouldingName: string;
      total: string;
}
interface PIGridColumn {
      palletCode: string;
      sku: string;
      mouldingName: string;
      poNumber: string;
      quantity: number;

}
interface IDropDownData {
      name: string;
      code: string;
}
interface ColumnSortOrder{
      title: string,
      sortOrder: 'asc' | 'desc'
}

type ColumnGridX = GridColumn & {dataColumnName: string} & {sortOrder: 'asc' | 'desc'} & {isColumnSorted: boolean} & {kind: GridCellKind}


const PalletInventoryListReport = () => {

      /// this should likely just list both alloy and premiere for a complete view
      const [clipboard, setClipBoard] = useState<string>("");

      const [dataFormatted, setDataFormatted] = useState<TableRow[]>([]);
      const [batchNotEnded, setBatchNotEnded] = useState<boolean>( false );

      const [frameType, setFrameType] = useState<'premiere'|'alloy'>('premiere');
      const params = useParams();

      const url =  params && params.batchid && `${Config.baseUrl}/api/temp-pallet-count/inventory/by-pallets/${params.batchid}`;

      const { data, loading, error } = useFetch<PalletCountInventoryLookupResult>(url ?? "", [], {});

      const [gridDataOriginal, setGridDataOriginal] = useState<PIGridColumn[]>( [] );

      const [gridData, setGridData] = useState<PIGridColumn[]>( [] );
      const [frameStyles, setFrameStyles] = useState<IDropDownData[]>([]);

      const [totalInitial, setTotalInitial] = useState<number>(0);
      const [totalConsumed, setTotalConsumed] = useState<number>(0);
      const [totalRemaining, setTotalRemaining] = useState<number>(0);
      const [sortOrderPerColumn, setSortOrderPerColumn] = useState<ColumnGridX[]>([]);
      const [selectedFrameStyle, setSelectedFrameStyle] = useState<IDropDownData | null>(null);
      const [sort, setSort] = useState<number>();


      const columns: ColumnGridX[] = [
            { id: "1", title: "Pallet Code", dataColumnName: 'palletCode', width: 100, sortOrder: 'asc', isColumnSorted: true, kind: GridCellKind.Text },
            { id: "2", title: "Sku", dataColumnName: 'sku', width: 150, sortOrder: 'asc', isColumnSorted: false, kind: GridCellKind.Text },
            { id: "3", title: "Name", dataColumnName: 'mouldingName', width: 200, sortOrder: 'asc', isColumnSorted: false, kind: GridCellKind.Text },
            { id: "4", title: "PO", dataColumnName: 'poNumber', width: 100, sortOrder: 'asc', isColumnSorted: false, kind: GridCellKind.Text },
            { id: "5",title: "Age", dataColumnName: 'age', width: 100, sortOrder: 'asc', isColumnSorted: false, kind: GridCellKind.Text },
            { id: "6",title: "Qty@Count", dataColumnName: 'quantity', width: 100, sortOrder: 'asc', isColumnSorted: false,kind: GridCellKind.Number },
            { id: "7",title: "Consumed", dataColumnName: 'consumed', width: 100, sortOrder: 'asc', isColumnSorted: false,kind: GridCellKind.Boolean },
            { id: "8",title: "Consumed Date", dataColumnName: 'consumedDate', width: 150, sortOrder: 'asc', isColumnSorted: false,kind: GridCellKind.Text },
            { id: "9",title: "Qty Consumed", dataColumnName: 'consumedQuantity', width: 150, sortOrder: 'asc', isColumnSorted: false,kind: GridCellKind.Number },
            { id: "10",title: "Qty Remaining", dataColumnName: 'quantityRemaining', width: 100, sortOrder: 'asc', isColumnSorted: false,kind: GridCellKind.Number },
      ];
      
    /*
      const moveArgs = useMoveableColumns({
            columns: columns,
            rows: gridData.length,
            getCellContent: useCallback(([col, row]) => {
              
                  const rowdata = gridData[row];
                  let columnName = getColumnName( col );

                  let data =  (rowdata as any)[ columnName ];
                  if ( typeof data == "boolean") {
                        data = data === true ? "yes" : ""
                  } else {
                        data = data ? data.toString() : "";
                  }

                  return {
                        kind: GridCellKind.Text,
                        data: data,
                        allowOverlay: false,
                        displayData: data,
                  };

            }, []),
        });
*/
/*
       // (cell: Item) 
        const getCellContent = useCallback( ( cell: Item ) => {
              
            const rowdata = gridData[cell[0]];
            let columnName = getColumnName(cell[1] );

            let data =  (rowdata as any)[ columnName ];
            if ( typeof data == "boolean") {
                  data = data === true ? "yes" : ""
            } else {
                  data = data ? data.toString() : "";
            }

            return {
                  kind: GridCellKind.Text,
                  data: data,
                  allowOverlay: false,
                  displayData: data,
            };

      }, [])

        const rows = 100000;
      const sortArgs = useColumnSort({
            columns: columns,
            getCellContent: useCallback(([col, row]) => {
              
                  const rowdata = gridData[row];
                  let columnName = getColumnName( col );

                  let data =  (rowdata as any)[ columnName ];
                  if ( typeof data == "boolean") {
                        data = data === true ? "yes" : ""
                  } else {
                        data = data ? data.toString() : "";
                  }

                  return {
                        kind: GridCellKind.Text,
                        data: data,
                        allowOverlay: false,
                        displayData: data,
                  };

            }, []),
            rows, 
            sort:
                sort === undefined
                    ? undefined
                    : {
                          column: moveArgs!.props.columns[sort],
                          direction: "desc",
                          mode: "smart",
                      },
        });

        const onHeaderClick = useCallback((index: number) => {
            setSort(index);
        }, []);
   */
    
      useEffect(() => {

            setSortOrderPerColumn( columns );

            function getData() {

                  let y = data; 
                  let columns: PIGridColumn [] = [];
                  let uniqueFrameStyles: {name: string, code: string}[] = [];

                  let initialQuantity = 0; 
                  let consumedQuantity = 0;
                  let remainingQuantity = 0; 

                  if ( data && data.palletsAtLastInventory ) {
                        
                        // eslint-disable-next-line array-callback-return
                        for ( let entry of data.palletsAtLastInventory ) {
      
                              let palletCode = entry.inventoryHistoryPallet.palletCode;
      
                              // eslint-disable-next-line array-callback-return
                              for ( let item of entry.inventoryHistoryPallet.inventoryHistoryPalletItems ) {
                                    const consumedPallet = data.palletsConsumedSinceLastInventory.find( e=> e.palletCode === palletCode );
                                    let wasConsumed = consumedPallet === undefined ? false : true;
                                    const consumedDateTime = consumedPallet?.deletedDateTimeLocal;
                                    let consumedDate = "";
                                    if ( consumedDateTime ) {
                                          consumedDate = consumedDateTime.substring(0, consumedDateTime.indexOf("T"))
                                    }
                                    
                                    const po =  item.purchaseOrderLineReceived.purchaseOrderLine.po.poNumber; //item.purchaseOrderLineReceived.
                                    const receivedDate = item.purchaseOrderLineReceived.purchaseOrderLine.po.receivedDate
                                    if ( ! uniqueFrameStyles.find( x => x.code === item.productMoulding.frameStyle.frameStyle )) {
                                          uniqueFrameStyles.push( { name: item.productMoulding.frameStyle.frameStyle, code: item.productMoulding.frameStyle.frameStyle } as IDropDownData );
                                    }

                                    initialQuantity += item.quantity;
                                    consumedQuantity += wasConsumed === true ? item.quantity : 0;
                                    remainingQuantity += wasConsumed === true ? 0 : item.quantity;

                                    columns.push ( {
                                          palletCode: palletCode,
                                          sku: item.productMoulding.sku,
                                          mouldingName: item.productMoulding.frameStyle.frameStyle,
                                          poNumber: po,
                                          age: receivedDate,
                                          quantity: item.quantity,
                                          consumed: wasConsumed,
                                          consumedDate:  consumedDate,
                                          consumedQuantity: wasConsumed === true ? item.quantity : 0,
                                          quantityRemaining:  consumedPallet ? 0 : item.quantity,
                                    } as PIGridColumn )
                                    
                              };
      
                              
                        };

                        setFrameStyles( uniqueFrameStyles );
                        setGridDataOriginal( columns ); // a copy to restore after filtering/searching
                        setGridData( columns );

                        setTotalInitial( initialQuantity );
                        setTotalConsumed( consumedQuantity );
                        setTotalRemaining( remainingQuantity );
                  }
                  // {pallet.inventoryHistoryPallet.palletCode}   {item.productMoulding.sku}   {item.productMoulding.frameStyle.frameStyle}  QTY: {item.quantity}
            }

            getData()
      }, [params, data]);

      function getColumnName( col: number) {
            let columnName = col === 0 ? "palletCode" : col === 1 ? "sku" : col === 2 ? "mouldingName" : col === 3 ? "poNumber" : col === 4 ? "age" : col === 5 ? "quantity" : col === 6 ? "consumed" : col === 7 ? "consumedDate" :  col === 8 ? "consumedQuantity" :  col === 9 ? "quantityRemaining" : "not set properly";
            return columnName;
      }

      function getCellData([col, row]: Item): GridCell {

            const rowdata = gridData[row];
            let columnName = columns[col].dataColumnName;

            let data =  (rowdata as any)[ columnName ];
            if ( typeof data == "boolean") {
                  data = data === true ? true: false;
            } else {
                  data = data ? data.toString() : "";
            }

            return {
                  kind: columns[col].kind,
                  data: data,
                  allowOverlay: false,
                  displayData: data,
            } as GridCell;

      }

      function onHeaderClicked( col:number, event: HeaderClickedEventArgs ) {
            // sort the data by the column head
            let columnName =  columns[col].dataColumnName;

            var isNumber = function isNumber(value:any) 
            {
                  return typeof value === 'number' && isFinite(value);
            }

            function sortBy(arr:any[], columnName: string )  {

                  const sortColumn =  sortOrderPerColumn.find( c=> c.dataColumnName === columnName)!;
                  setSortOrderPerColumn( order => {
                        const sortColumn =  sortOrderPerColumn.find( c=> c.dataColumnName === columnName)!;
                        const newSortOrder = sortColumn!.sortOrder  === 'asc' ? "desc" : 'asc';

                        if ( sortColumn ) {
                              // this is readonly .. but works .. may not deploy without modifying type definitions
                               // @ts-ignore 
                              sortColumn!.title = `${sortColumn!.title.replace(' ↑', '').replace(' ↓', '') } ${newSortOrder === 'asc' ? '↑' : '↓'}`;
                              sortColumn!.sortOrder = newSortOrder;
                              sortColumn!.isColumnSorted = true;
                              //reset other columns to asc.. only sort by one column at a time 
                              let others = sortOrderPerColumn.filter( o => o.dataColumnName !== columnName).map( o =>{ 
                                    o.sortOrder = 'asc';
                                    o.isColumnSorted = false;
                                    // this is readonly .. but works .. may not deploy without modifying type definitions
                                     // @ts-ignore 
                                    o.title =  o.title.replace(' ↑', '').replace(' ↓', '');
                                    return o;
                               } );
      
                              return [sortColumn, ...others].toSorted((a,b)=>{ return parseInt(a.id!) - parseInt(b.id!); } );
                        } else {
                              return [...order];
                        }
                  });
      
                  let newArray = [...arr];      
                  let order =( sortColumn.sortOrder === 'asc' ? -1 : 1 ) ; 
                  
                  return newArray.sort( (a: any, b: any) => {
                        if ( a && !b ){
                              return 0 ; 
                        } else if ( !a && b) {
                              return 0; 
                        } else if (typeof  a[columnName] === "number" && typeof  a[columnName] ==='number') {
                              if ( a[columnName] - b[columnName] === 0 ) {
                                    return 0; 
                              } else {
                                    return (a[columnName]- b[columnName]) * ( order) ; ;
                              }
                        } else if (typeof  a[columnName] === "boolean" && typeof  a[columnName] ==='boolean') {
                              if ( a[columnName] === true === b[columnName] === true ) {
                                    return 0
                              } else if ( a[columnName] === true && b[columnName] === false ){
                                    return 1  * ( order) ;; 
                              } else if ( a[columnName] === false && b[columnName] === true  ){
                                    return -1  * ( order) ;;
                              } else {
                                    return 0;;
                              }
                        } else { // string
                              if ( a[columnName] && b[columnName]) {
                                    return  a[columnName].localeCompare( b[columnName]) * ( order); 
                              } else if ( !a[columnName] && b[columnName]) {
                                    return -1 *(order)
                              } else if ( a[columnName] && !b[columnName]) {
                                    return 1 *(order)
                              } else {
                                    return 0;
                              }
                        }

                  })
            }


            const sortedDate = sortBy ( gridData, columnName);

            
            setGridData( sortedDate )
      }
     
      function filterByFrameStyle( style: IDropDownData ) {

            if ( !style ) {
                  setSelectedFrameStyle(null);

                  // show all
                  setGridData( gridDataOriginal );
                //  setTotalInitial( initialQuantity );
                //  setTotalConsumed( consumedQuantity );
               //   setTotalRemaining( remainingQuantity );

            } else {

                  setSelectedFrameStyle(style);

                  let fitleredData = gridDataOriginal.filter( row => row.mouldingName === style.code );
                  setGridData( fitleredData );

                  const initialQuantity = fitleredData.map( e=> e.quantity).reduce( (previous, current ) => previous + current, 0 );
               //   const palletsStart = fitleredData.map( e => e.palletCode );
                  const palletsConsumed = data.palletsConsumedSinceLastInventory.map( e=> e.palletCode );
                  const consumedQuantity = fitleredData.filter( e=>  palletsConsumed.includes(e.palletCode)).map( x=> x.quantity).reduce( (previous, current ) => previous + current, 0 );
                  const remainingQuantity = (initialQuantity ?? 0) - (consumedQuantity ?? 0)

                  setTotalInitial( initialQuantity );
                  setTotalConsumed( consumedQuantity );
                  setTotalRemaining( remainingQuantity );
            }
      }

      return (
            <div className='report'>
                  <Card>
                        <div className="report-title-wrapper">
                              <div className="report-title"> Pallet Inventory Batch Summary</div> 
                              { data && data.batchInformation && 
                                    <div className="report-date-title"> 
                                          { data.batchInformation.inventoryLocationName }  |
                                          { data.batchInformation.startedDateLocal }  |
                                          { data.batchInformation.batchState }  |
                                          { data.batchInformation.startByUserName } 
                                    </div>                     
                              }
                        </div>
         
                      
                        <div style={{ textAlign: "left", backgroundColor: "#5587da", color: "white:", padding: "7px" }}>
                              Total Boxes@Count:  <span style={{ color: "white", backgroundColor: "#505050", borderRadius: "5px", padding: "2px 7px", fontWeight: "bold"}}>{totalInitial}</span>  |
                              Consumed Boxes:  <span style={{ color: "white", backgroundColor: "#505050", borderRadius: "5px", padding: "2px 7px", fontWeight: "bold"}}>{totalConsumed}</span>  |
                              Remaining Boxes:  <span style={{ color: "white", backgroundColor: "#505050", borderRadius: "5px", padding: "2px 7px", fontWeight: "bold"}}>{totalRemaining}</span>
                        </div>

                        <div className="card flex justify-content-center">
                              <Dropdown 
                                    value={selectedFrameStyle} 
                                    onChange={(e) => { 
                                                       filterByFrameStyle( e.value );

                                                }}
                                    options={frameStyles} 
                                    optionLabel="name" 
                                    placeholder="Filter by style"
//                                    className="w-full md:w-14rem" 
                                    checkmark={true}
                                    highlightOnSelect={false} 
                                    editable
                                    showClear
                               />
                        </div>

                        <DataEditor        
      
                             /* {...sortArgs} */
                              getCellContent={getCellData} 
                              columns={sortOrderPerColumn} 
                              rows={gridData.length} 
                              getCellsForSelection={true}
                              onHeaderClicked={onHeaderClicked} />

                       
                      {/*
                              { data && data.palletsAtLastInventory  && data.palletsAtLastInventory.map( pallet => {

                              <DataEditor getCellContent={getData} columns={columns} rows={gridData.length} />


                              return (
                                    <div className="flex justify-content-start align-items-center">
                                          
                                          <div className="flex p-1 align-content-start flex-column">
                                                {pallet.inventoryHistoryPallet.inventoryHistoryPalletItems && pallet.inventoryHistoryPallet.inventoryHistoryPalletItems.map( item => {

                                                      return (
                                                            <div>
                                                            {pallet.inventoryHistoryPallet.palletCode}   {item.productMoulding.sku}   {item.productMoulding.frameStyle.frameStyle}  QTY: {item.quantity}
                                                            </div>
                                                      )

                                                })}
                                          </div>
                                    {pallet.inventoryHistoryPallet.note}
                                    </div>
                              )
                              })}
                        */}
                        { error && <p>An error occurred loading the report <span>{error}</span></p> }
                        { batchNotEnded && <p style={{ color: 'navy' }} > There is no data to display because this batch was {batchNotEnded}</p> }
                        { loading && <p> <ProgressSpinner /> Loading... </p> }

                  </Card>
            </div>
      )
}

export default PalletInventoryListReport;


