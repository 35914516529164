import FrameEntryModel from "../models/frame-entry.model"
import './box-label-premiere.css'
import FrameImage from "../frame-image"
import FrameLookupDTO from "../models/frame-lookup.dto"
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { ReactBarcode } from 'react-jsbarcode';

interface IBoxLabelProps {
      frameLookup: FrameLookupDTO
      boxedBy: string, // looked up in the history after it loads
      boxedDate: string,  // looked up in the history after it loads
}
//<div className='text-4xl p-2' >  {props.frameLookup.frame?.name} {props.frameLookup.frame?.size}"</div>
//AP021252115
export const BoxLabelDecoSlim = (props: IBoxLabelProps) => {

      const [yearRange, setYearRange] = useState('');
      const [colorBarBackgroundColor, setColorBarBackgroundColor] = useState('');

      /*
                  let dateStringRange = serial.index(serial.startIndex, offsetBy: 2) ..< serial.index(serial.endIndex, offsetBy: -4)
            let dateString = serial[dateStringRange];
            let dayOfYearRange = dateString.index(dateString.startIndex, offsetBy: 0) ..< dateString.index(dateString.endIndex, offsetBy: -2)
            let yearRange = dateString.index(dateString.startIndex, offsetBy: 3) ..< dateString.endIndex
            
            let dayOfYear = dateString[dayOfYearRange]
            let year = dateString[yearRange]
      */

      function is2025(): boolean {
            let serial = props.frameLookup.frame?.serialId;
            const dateString = serial?.substring(2, serial.length - 4)
            const dayOfYear = dateString!.substring(0, dateString!.length - 2);
            let year = dateString?.substring(3);
            if (parseInt(year!)! >= 25) {
                  return true
            } else if (parseInt(dayOfYear)! >= 351 && parseInt(year!)! === 24) {
                  return true;
            } else {
                  return false;
            }
      }

      useEffect(() => {
            function getYearLabelBackgroundColor() {
                  // 32 inch will work for all years so don't color the background
                  if (props.frameLookup.frame?.size.startsWith("32") === true) {
                        setColorBarBackgroundColor("#EEEEEE")
                  } else if (yearRange === "2021-2025") {
                        setColorBarBackgroundColor("#57b9ff") // darker light blue
                  } else if (yearRange === "2021-2024") {
                        setColorBarBackgroundColor("#8FF703") //green                  
                  } else if (yearRange === "2017-2020") {
                        setColorBarBackgroundColor("#FF0d83")
                  } else {
                        setColorBarBackgroundColor("#FFFFFF") // unknown
                  }
            }
            getYearLabelBackgroundColor()
      }, [yearRange]) // <-- empty dependency array

      useEffect(() => {
            function getYearRange(): string {

                  if (props.frameLookup.frame?.year === 2020) {
                        setYearRange("2017-2020")
                        return "2017-2020"
                  } else if (props.frameLookup.frame?.year === 2021) {
                        if (is2025() === true) {
                              setYearRange("2021-2025")
                              return "2021-2025"
                        } else {
                              setYearRange("2021-2024")
                              return "2021-2024"
                        }
                  } else {
                        setYearRange(props.frameLookup.frame!.year!.toString())
                        return props.frameLookup.frame!.year.toString()
                  }

            }
            getYearRange();
      }, []) // <-- empty dependency array


      return (
            <div className="box-label-premiere flex">
                  {props.frameLookup.frame?.isOpenBox &&
                        <div className="open-box-overlay flex flex-column">
                              <div>Open</div>
                              <div>Box</div>
                        </div>

                  }
                  <div className="left-color-bar flex" style={{ backgroundColor: colorBarBackgroundColor }}>
                        <div className="qr-code">
                              <QRCode format={"CODE128"} value={`https://decotvframes.com/pages/myframe?sr=${props.frameLookup.frame!.serialId!}`} />
                        </div>
                        <div className="bar-text">
                              <div className="compatible-with">Compatible with</div>
                              <div className="samsung-the-frame">Samsung The Frame</div>
                              <div className="date-range" style={{ fontSize: "3.5rem" }}>{yearRange}</div>
                        </div>
                        <div className="serial">
                              SN: {props.frameLookup.frame?.serialId}
                        </div>
                  </div>
                  <div className="right-column flex flex-column" style={{ width: "100%" }}>
                        <div className="top-row flex flex-row align-content-between">
                              {/**IMAGE */}
                              <div className="frame-image" style={{ width: "50%" }}>
                                    <div className="flex flex-column justify-content-center h-full align-items-center">

                                          <img  src={process.env.PUBLIC_URL + `/img/frames/${props.frameLookup.frame?.image ?? "NOT_FOUND"}.jpg`} alt={props.frameLookup.frame?.image ?? "NOT_FOUND"} />
                             
                                          <div className="flex flex-row align-items-center">
                                                <div className="flex flex-column">
                                                      <div className="fits">Proudly boxed by:</div>
                                                      <div className="fits">{props.boxedBy ?? ""}</div>
                                                      <div className="fits">{props.boxedDate  ?? ""}</div>
                                                </div>
                                                <div>
                                                      <img style={{ height: "4.4rem", marginLeft: "3px" }} src={process.env.PUBLIC_URL + `/img/made-in-use-transparent.png`} alt="made-in-usa-logo" />

                                                </div>
                                          </div>

                                    </div>
                                    {props.frameLookup.isVoid === true &&
                                          <div className='voided text-red-600 text-7xl'> Voided </div>
                                    }



                              </div>

                              {/**DESCRIPTION AT BOTTOM */}
                              <div className="frame-description flex flex-column" style={{ width: "50%" }}>
                                    <div style={{ height: "43%" }} className="frame-style flex flex-row">
                                          <div className="frame-style-name" style={{ marginTop: "46px"}}>{ `Slim ${props.frameLookup.frame?.name ?? "not found"}` }</div>
                                          <div className={`triangle size-${props.frameLookup.frame!.size}`}>
                                                <div className="frame-size-in-corner">{props.frameLookup.frame?.size ?? "not found"}"</div>
                                          </div>

                                    </div>
                                    <div className="frame-description-lines">
                                          <div className="fits">Fits <b> {props.frameLookup.frame?.size}"</b> Samsung The Frame TV's</div>
                                          <div className="fits">SKU: {props.frameLookup.frame?.skuInternal}</div>
                                          <div style={{marginLeft:"43px"}} >
                                                  <ReactBarcode style={{ height: "119px" }} options={{ height: 50}} value={props.frameLookup.frame!.barCode} />
                                          </div>

                                    </div>
                              </div>
                        </div>

                        <div className="footer-bar decoslim flex flex-column w-full align-content-center">
                              <div className="bottom-bar"> </div>
                              <div className="flex flex-row justify-content-between" style={{marginTop: "24px"}}>
                                    <img className="decoslim-collection-logo" src={process.env.PUBLIC_URL + `/img/deco-slim-collection.png`} alt="deco-tv" />
                                    <div className="flex flex-row w-full justify-content-end" >
                                          <div className="flex flex-column align-self-center" style={{ marginRight: "5%" }}>
                                                <div>Made By Frame My TV</div>
                                                <div>Made in Methuen , MA</div>
                                          </div>
                                          <img style={{ marginLeft: "5%", width: "auto", height:"58px"}} src={process.env.PUBLIC_URL + `/img/deco-tv-logo.png`} alt="deco-tv" />
                                    </div>
                              </div>
                        </div>
                  </div>

            </div>

      )

}